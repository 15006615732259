<template>
  <div class="maropost-product">
    <div
      @mouseover="setIsHover(true)"
      @mouseleave="setIsHover(false)"
      v-click-outside="onClickOutside"
    >
      <maropost-product-info
        v-if="!isButtonHovered && showInfoCard"
        :product="product"
        :show-card="showInfoCard"
      />
      <v-card
        v-else
        width="340px"
        :elevation="isHover ? 12 : 2"
        height="280px"
        class="maropost-product-details light-grey--background"
        :style="`border-left: 60px solid ${product.color} !important;`"
        :id="`product-details-${product.id}`"
      >
        <v-row no-gutters class="d-flex">
          <v-col cols="12" class="mt-5 justify-center d-flex flex-column">
            <img
              height="120px"
              class="white--text align-end"
              :src="`${$images[product.image]}`"
              :alt="`${capitalize(product.id)} Logo`"
              :id="`product-details-${product.id}-img`"
            />
            <v-card-text class="text--primary mt-5">
              <div class="caption" :class="product.introClass">
                {{ product.intro }}
              </div>
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters class="mt-4">
          <v-col class="d-flex justify-end">
            <v-btn
              text
              depressed
              :color="product.color"
              @click="onLearnMoreBtnClick(product)"
              @mouseover="setIsButtonHovered(true)"
              @mouseleave="setIsButtonHovered(false)"
              v-track="`${product.id}-learn-more-btn`"
              class="caption secondary--font font-weight-bold px-2 mr-4"
            >
              Learn More
              <v-icon
                v-text="'arrow_forward_ios'"
                class="material-symbols-outlined"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import MaropostProductInfo from "@/views/ProductsLandingPage/MaropostProductInfo.vue";
import { defer, isType } from "@/utils";

/**
 * Displays maropost product details
 * @author {Jatin Kamboj}
 */
export default {
  name: "MaropostProduct",
  /**
   * ------------ Components ------------
   */
  components: {
    MaropostProductInfo,
  },
  /**
   * ------------ Data properties ------------
   */
  data() {
    return {
      isButtonHovered: false,
      isHover: false,
      showInfoCard: false,
      timerRef: null,
    };
  },
  /**
   * ------------ Props ------------
   */
  props: {
    product: { type: Object, required: true, default: () => ({}) },
  },
  /**
   * ------------ Methods ------------
   */
  methods: {
    /**
     * onLearnMoreBtnClick
     * @description Opens a new tab with the link
     */
    onLearnMoreBtnClick({ learnMore }) {
      window.open(learnMore, "_blank");
    },
    /**
     * capitalize
     * @description  capitalised first character of a string
     */
    capitalize(value) {
      return this.$options.filters.capitalize(value);
    },
    /**
     * clearCardTimerRef
     * Clears the timer ref in event loop
     */
    clearCardTimerRef() {
      if (!this.timerRef) return;
      clearTimeout(this.timerRef);
      this.timerRef = null;
    },
    /**
     * setIsButtonHovered
     */
    setIsButtonHovered(value) {
      this.isButtonHovered = value;
      if (!value && isType(value, "boolean")) {
        this.setIsHover(false);
        this.clearCardTimerRef();
      }
    },
    /**
     * Updates value if isHover data property
     */
    setIsHover(value) {
      this.isHover = value;
      if (value) {
        this.clearCardTimerRef();
        this.timerRef = defer(() => this.setShowInfoCard(value), 600);
      } else {
        this.clearCardTimerRef();
        this.setShowInfoCard(false);
      }
    },
    setShowInfoCard(value) {
      this.showInfoCard = value;
    },
    /**
     * onClickOutside
     * @description Handles outside click
     */
    onClickOutside() {
      this.isButtonHovered = false;
      this.isHover = false;
      this.showInfoCard = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.maropost-product-details {
  border-radius: 20px !important;
}
</style>
