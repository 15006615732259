<template>
  <section id="products-landing-page" class="mb-8">
    <v-row class="d-flex justify-center py-4">
      <v-col cols="10" lg="10" md="10">
        <h1 class="text-h6 secondary--font font-weight-large">Products</h1>
        <v-row
          no-gutters
          class="d-flex justify-center mt-8 pt-8 products-landing-page-wrapper"
        >
          <template v-for="product in products">
            <v-col
              md="4"
              cols="12"
              :key="product.id"
              :class="isMobile ? `d-flex justify-center mt-5` : product.class"
            >
              <maropost-product :product="product" />
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { isMobile } from "@/utils";
import { maropostProducts as products } from "@/data";
import MaropostProduct from "@/views/ProductsLandingPage/MaropostProduct.vue";

/**
 * Products Landing Pages
 * @author {Jatin Kamboj}
 */
export default {
  name: "ProductsLandingPage",
  /**
   * ------------ Components ------------
   */
  components: {
    MaropostProduct,
  },
  /**
   * ------------ Data Properties ------------
   */
  data() {
    return {
      products,
    };
  },
  /**
   * ------------ Computed Properties ------------
   */
  computed: {
    isMobile() {
      return isMobile(this.$vuetify);
    },
  },
};
</script>

<style lang="scss" scoped>
.products-landing-page-wrapper {
  .product-margin-top {
    margin-top: 108px !important;
  }

  .product-margin-left {
    margin-left: 40px !important;
  }

  .product-margin-right {
    margin-right: 40px !important;
  }
}
</style>
