<template>
  <div class="maropost-product-info">
    <v-card
      width="340px"
      height="280px"
      color="light-grey--background"
      class="maropost-product-info-card"
      :elevation="showCard ? 12 : 2"
      :style="`border-right: 60px solid ${product.color} !important;`"
      :id="`maropost-product-info-${product.id}`"
    >
      <v-row no-gutters class="d-flex pl-8 fill-height">
        <v-col cols="12" class="mt-5 justify-center d-flex flex-column">
          <div
            class="
              body-2
              secondary--font
              white-space-preline
              line-height-heading
            "
            v-text="product.title"
          />

          <div
            class="pt-3 maropost-product-info-heading"
            :class="product.headingClass"
            :style="product.hasChecklist && `color:${product.color}`"
          >
            {{ product.heading }}
          </div>
          <div
            v-if="product.hasChecklist"
            class="pt-1 maropost-product-info-checklist"
          >
            <template v-for="(item, i) in product.checkList">
              <div
                class="
                  pt-1
                  caption
                  font-weight-medium
                  d-flex
                  maropost-product-info-checklist-item
                "
                :key="item[1]"
                :class="{ 'checklist--item': isLastItem(i) }"
              >
                <div class="pr-2 checklist--item__details">
                  <v-icon
                    :color="product.color"
                    class="material-symbols-outlined"
                  >
                    done
                  </v-icon>
                </div>
                <div>
                  <span class="font-weight-bold">
                    {{ item[0] }}
                  </span>
                  <span class="white-space-preline">
                    {{ item[1] }}
                  </span>
                  <span v-if="item && item[2]" class="font-weight-bold">
                    {{ item[2] }}
                  </span>
                </div>
              </div>
            </template>
          </div>

          <v-row
            class="
              d-flex
              fill-height
              align-content-end
              maropost-product-info-card-button
            "
            :class="product.button.class"
            no-gutters
          >
            <v-btn
              large
              link
              elevation="2"
              @click="openLinkInWindow(product.trialLink)"
              :color="product.button.color"
              v-track="`${product.id}-talk-to-sales-btn`"
              class="white--text font-weight-bold secondary--font px-4"
            >
              {{ product.button.text }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { openLinkInWindow } from "@/utils";
/**
 * Displays maropost product info
 * @author {Jatin Kamboj}
 */
export default {
  name: "MaropostProductInfo",
  /**
   * ------------ Props ------------
   */
  props: {
    product: { type: Object, required: true, default: () => ({}) },
    showCard: { type: Boolean, default: false },
  },
  /**
   * ------------ Methods ------------
   */
  methods: {
    openLinkInWindow,
    isLastItem(index) {
      return this.product?.checkList?.length - 1 === index;
    },
  },
};
</script>

<style lang="scss" scoped>
.maropost-product-info {
  .maropost-product-info-card {
    border-radius: 20px !important;

    .maropost-product-info-heading {
      &.line-height {
        line-height: 28px !important;
      }
    }
    .checklist--item {
    }
    .line-height-heading {
      line-height: 28px !important;
    }
  }
}
</style>
